// @flow
import React from 'react'

import colors from 'superdays-shared/colors'
import Avatar from 'superdays-shared/components/Avatar'
import Media from 'react-media'

import {type UserReference} from 'superdays-shared/types'

import ResponsiveSlider from '../components/ResponsiveSlider'
import {StripWithContent, Col, Row, Card} from '../ui/layout'
import typo, {ExternalOrInternalLink, fontStyles} from '../ui/typo'
import {breakPoints} from '../ui/breakpoints'

import testimonialData from '../testimonial-data.json'

type Testimonial = {
  owner: UserReference,
  testimonial: string,
  dailyId: string,
  nDays: number,
  nEvents: number,
  nFriends: number,
}
// $FlowFixMe
const testimonials: Testimonial[] = Object.values(testimonialData)

const numberStyle = {
  textAlign: 'center',
  color: colors.periwinkle,
}
const Stats = ({testimonial}) => (
  <Row spacing={3} breakAt={breakPoints.mobile}>
    <Col align="center">
      <typo.H2 css={numberStyle}>{testimonial.nDays}</typo.H2>
      <typo.SmallBold>days</typo.SmallBold>
    </Col>
    <Col>
      <typo.H2 css={numberStyle}>{testimonial.nEvents}</typo.H2>
      <typo.SmallBold>events</typo.SmallBold>
    </Col>
    <Col>
      <typo.H2 css={numberStyle}>{testimonial.nFriends}</typo.H2>
      <typo.SmallBold>friends</typo.SmallBold>
    </Col>
  </Row>
)

const TestimonialCard = ({testimonial}) => (
  <div css={{width: '100%'}}>
    <Col paddingHorizontal={2} paddingVertical={4}>
      <Card>
        <Col spacing={2} padding={2} align="center">
          <Avatar user={testimonial.owner} size={100} />
          <typo.H3 css={{textAlign: 'center'}}>{testimonial.owner.displayName}</typo.H3>
          <Stats testimonial={testimonial} />
          <typo.Body css={{textAlign: 'center'}}>{testimonial.testimonial}</typo.Body>
          <ExternalOrInternalLink
            href={`https://daily.superdays.fun/${testimonial.dailyId}`}
            target="__blank"
            css={[fontStyles.bodyBold, fontStyles.link()]}
          >
            See more
          </ExternalOrInternalLink>
        </Col>
      </Card>
    </Col>
  </div>
)

const Testimonials = () => (
  <StripWithContent
    backgroundGradient={colors.lightGreenGradient}
    css={{
      [breakPoints.small]: {
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
      },
    }}
  >
    <Media query="(max-width: 700px)" defaultMatches={true}>
      {isSmallScreen => (
        <Col spacing={1} paddingBottom={4} paddingHorizontal={isSmallScreen ? 0 : 4}>
          <typo.H2 css={{textAlign: 'center'}}>And so say others!</typo.H2>
          <ResponsiveSlider slidesToShow={3} isDark>
            {testimonials.map(testimonial => (
              <TestimonialCard key={testimonial.owner.uid} testimonial={testimonial} />
            ))}
          </ResponsiveSlider>
          <Col align="center">
            <typo.Body css={{textAlign: 'center', maxWidth: '50rem'}}>
              If you’d like to see your pretty face up here, let’s get to it and sign up for the
              beta!
            </typo.Body>
          </Col>
        </Col>
      )}
    </Media>
  </StripWithContent>
)

export default Testimonials
