// @flow
import * as React from 'react'
import colors from 'superdays-shared/colors'
import {fontStyles} from './typo'

const inputStyle = {
  ...fontStyles.body,
  display: 'block',
  width: '100%',
  backgroundColor: '#fff',
  padding: '0.6rem 1.5rem',
  borderRadius: '2rem',
  border: `1px solid ${colors.borderColor}`,
  boxShadow: '0 0.2rem 1rem -0.4rem rgba(0,0,0,0.2)',
  ':focus': {
    border: `1px solid ${colors.mintGreen}`,
  },
}

type RIProps = {
  withButtonWithin?: boolean,
  inputRef?: React.ElementRef<any>,
}
const RawInput = ({withButtonWithin, inputRef, ...props}: RIProps) => (
  <input ref={inputRef} css={[inputStyle, withButtonWithin && {paddingRight: '4rem'}]} {...props} />
)

const buttonContainerStyle = {
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  padding: '0.5rem',
}

type InputProps = {
  buttonWithin: React.Node,
}
const Input = ({buttonWithin, ...rest}: InputProps) =>
  buttonWithin ? (
    <div css={{position: 'relative'}}>
      <RawInput withButtonWithin {...rest} />
      <div css={buttonContainerStyle}>{buttonWithin}</div>
    </div>
  ) : (
    <RawInput {...rest} />
  )

export default Input
