// @flow
import * as React from 'react'
import colors from '../colors'

import {type UserReference} from '../types'

// Created with a little script using 'color-interpolate' and this Stackoverflow answer:
// https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
const interpolatedColors = [
  {rgbValue: 'rgb(107,153,255)', isFontDark: false},
  {rgbValue: 'rgb(106,158,249)', isFontDark: false},
  {rgbValue: 'rgb(104,163,244)', isFontDark: false},
  {rgbValue: 'rgb(103,168,238)', isFontDark: false},
  {rgbValue: 'rgb(101,172,233)', isFontDark: false},
  {rgbValue: 'rgb(100,177,227)', isFontDark: false},
  {rgbValue: 'rgb(98,182,221)', isFontDark: false},
  {rgbValue: 'rgb(97,187,216)', isFontDark: false},
  {rgbValue: 'rgb(95,192,210)', isFontDark: false},
  {rgbValue: 'rgb(94,197,204)', isFontDark: false},
  {rgbValue: 'rgb(92,201,199)', isFontDark: false},
  {rgbValue: 'rgb(91,206,193)', isFontDark: false},
  {rgbValue: 'rgb(89,211,188)', isFontDark: false},
  {rgbValue: 'rgb(88,216,182)', isFontDark: false},
  {rgbValue: 'rgb(101,217,175)', isFontDark: false},
  {rgbValue: 'rgb(114,218,168)', isFontDark: false},
  {rgbValue: 'rgb(127,219,161)', isFontDark: false},
  {rgbValue: 'rgb(139,219,154)', isFontDark: true},
  {rgbValue: 'rgb(152,220,147)', isFontDark: true},
  {rgbValue: 'rgb(165,221,140)', isFontDark: true},
  {rgbValue: 'rgb(178,222,133)', isFontDark: true},
  {rgbValue: 'rgb(191,223,126)', isFontDark: true},
  {rgbValue: 'rgb(204,224,119)', isFontDark: true},
  {rgbValue: 'rgb(216,224,112)', isFontDark: true},
  {rgbValue: 'rgb(229,225,105)', isFontDark: true},
  {rgbValue: 'rgb(242,226,98)', isFontDark: true},
]

function hashCode(str) {
  return str
    .split('')
    .reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0)
}

type AvatarProps = {
  user: UserReference,
  size: number,
  bordered?: boolean,
  style?: Object,
}

export default class Avatar extends React.Component<AvatarProps> {
  render() {
    const {user, size, style, bordered} = this.props
    const colorIndex = Math.abs(hashCode(user.uid)) % interpolatedColors.length
    const initialColor = interpolatedColors[colorIndex]
    const imageStyle = {
      width: size,
      height: size,
      borderRadius: '50%',
      ...(bordered ? {border: `2px solid ${colors.mintGreen}`} : {}),
      ...style,
    }
    const initialsStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: initialColor.isFontDark ? colors.text : colors.lightText,
      backgroundColor: initialColor.rgbValue,
      width: size,
      height: size,
      borderRadius: '50%',
      fontWeight: 'bold',
      fontSize: size * 0.6,
      ...style,
    }
    return user.avatarUrl ? (
      <img alt="avatar" src={user.avatarUrl} style={imageStyle} />
    ) : (
      <div style={initialsStyle}>{user.displayName[0]}</div>
    )
  }
}
