// @flow
import * as React from 'react'
import Slider from '../components/SSRSlider'
import Media from 'react-media'

import colors from 'superdays-shared/colors'

import {StripWithContent, Col, Row} from '../ui/layout'
import typo from '../ui/typo'
import {DotContainer, Dot} from '../components/ResponsiveSlider'

import BottomCurve from '../images/bottom-curve.svg'
import PhoneFrame from '../images/phone-frame.svg'
import ScreenSuperdaily from '../images/device-mocks/screen_superdaily.png'
import ScreenInspiration from '../images/device-mocks/screen_inspiration.png'
import ScreenFeedback from '../images/device-mocks/screen_feedback.png'
import ScreenDate from '../images/device-mocks/screen_date.png'
import ScreenComments from '../images/device-mocks/screen_comments.png'
import ScreenCalendar from '../images/device-mocks/screen_calendar.png'
import {breakPoints} from '../ui/breakpoints'

type ContentItem = {|
  id: number,
  title: string,
  description: string,
  screenshot: string,
|}
const content: ContentItem[] = [
  {
    title: 'Setup is done in a minute',
    description:
      'Just a few simple steps to set up Superdays and you’re ready to share the link with friends.',
    screenshot: ScreenDate,
  },
  {
    title: 'No app required for friends',
    description:
      'Friends direct access to the calendar. They don’t have to install the app, making it easy and fun to create events.',
    screenshot: ScreenCalendar,
  },
  {
    title: 'Inspiration is built-in',
    description: 'Superdays helps you and your friends to come up with creative ideas.',
    screenshot: ScreenInspiration,
  },
  {
    title: 'Planning as easy as pie',
    description: 'Events can be moved and commented to ensure that every idea has its place.',
    screenshot: ScreenComments,
  },
  {
    title: 'Share what happened',
    description:
      'After each event, the Superperson will be asked for feedback to share with the creator of the event.',
    screenshot: ScreenFeedback,
  },
  {
    title: 'Make memories',
    description: 'Superdays automagically creates a beautiful summary of everything that happened.',
    screenshot: ScreenSuperdaily,
  },
].map((item, id) => ({id, ...item}))

const itemHoverOrActiveStyle = {
  background: 'linear-gradient(180deg, #426FD2 0%, rgba(66, 111, 210, 0.2) 100%)',
  borderRadius: '4px',
  color: colors.white,
}
const itemStyle = {
  cursor: 'pointer',
  ':hover': itemHoverOrActiveStyle,
}
const Item = ({title, description, isActive, onClick}) => {
  return (
    <div onClick={onClick} css={[itemStyle, isActive && itemHoverOrActiveStyle]}>
      <Col padding={2}>
        <typo.H3>{title}</typo.H3>
        <typo.Body>{description}</typo.Body>
      </Col>
    </div>
  )
}

const ImageOverlay = ({item}) => (
  <div
    style={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: colors.roastedBlue,
      opacity: 0.9,
      borderRadius: '0 0 24px 24px',
      color: colors.white,
    }}
  >
    <Col padding={2} spacing={1} paddingBottom={4}>
      <typo.H3>{item.title}</typo.H3>
      <typo.Body>{item.description}</typo.Body>
    </Col>
  </div>
)

type SPProps = {
  items: ContentItem[],
  activeItemId: number,
  onItemChange: number => void,
  isSmallScreen: boolean,
}
class ScreenshotPhone extends React.Component<SPProps> {
  slider: any = null

  handleSliderInstance = (slider: any) => {
    this.slider = slider
  }

  render() {
    const {items, activeItemId, onItemChange, isSmallScreen} = this.props
    this.slider && this.slider.slickGoTo(activeItemId)
    return (
      <div
        css={{
          position: 'relative',
          width: 300,
          [breakPoints.notMini]: {
            width: 375,
          },
          flexShrink: 0,
          backgroundImage: `url(${PhoneFrame})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          paddingTop: 40,
          paddingBottom: 30,
          margin: 0,
        }}
      >
        <Slider
          arrows={false}
          dots={isSmallScreen}
          ref={this.handleSliderInstance}
          afterChange={onItemChange}
          appendDots={dots => <DotContainer dots={dots} />}
          customPaging={i => <Dot isActive={i === activeItemId} />}
        >
          {items.map(item => (
            <div key={item.id}>
              <div style={{position: 'relative'}}>
                <img css={{width: '100%', height: 'auto'}} alt={item.title} src={item.screenshot} />
                {isSmallScreen ? <ImageOverlay item={item} /> : null}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

const FeatureItemColumn = ({items, onItemClick, activeItemId}) => (
  <Col spacing={1} paddingTop={5} css={{flex: 1}}>
    {items.map(({title, description, id}) => {
      return (
        <Item
          key={id}
          onClick={() => onItemClick(id)}
          title={title}
          description={description}
          isActive={id === activeItemId}
        />
      )
    })}
  </Col>
)

type FSState = {
  activeItemId: number,
}
class FeaturesStrip extends React.Component<{}, FSState> {
  state = {
    activeItemId: 0,
  }

  handleActiveItemChange = (itemId: number) => {
    this.setState({activeItemId: itemId})
  }

  render() {
    const {activeItemId} = this.state
    return (
      <StripWithContent
        scrollKey="features-strip"
        backgroundGradient={colors.blueGradient}
        bottom={<img css={{width: '100%', marginTop: -70}} src={BottomCurve} />}
      >
        <Col align="center" spacing={3} paddingTop={2}>
          <typo.H2>Features</typo.H2>
          <Media query="(max-width: 900px)" defaultMatches={true}>
            {isSmallScreen => (
              <Row spacing={2}>
                {isSmallScreen ? (
                  <div style={{flex: 1}} />
                ) : (
                  <FeatureItemColumn
                    items={content.slice(0, 3)}
                    onItemClick={this.handleActiveItemChange}
                    activeItemId={activeItemId}
                  />
                )}
                <ScreenshotPhone
                  items={content}
                  activeItemId={activeItemId}
                  onItemChange={this.handleActiveItemChange}
                  isSmallScreen={isSmallScreen}
                />
                {isSmallScreen ? (
                  <div style={{flex: 1}} />
                ) : (
                  <FeatureItemColumn
                    items={content.slice(3, 6)}
                    onItemClick={this.handleActiveItemChange}
                    activeItemId={activeItemId}
                  />
                )}
              </Row>
            )}
          </Media>
        </Col>
      </StripWithContent>
    )
  }
}

export default FeaturesStrip
