// @flow
import React from 'react'
import RegisterForm from '../components/RegisterForm'
import colors from 'superdays-shared/colors'
import {StripWithContent, Col} from '../ui/layout'
import typo from '../ui/typo'

import LowerCurve from '../images/lower-curve.svg'
import SuperdaysSun from '../images/sun.svg'

const RegisterStrip = () => (
  <StripWithContent
    backgroundGradient={colors.yellowGradient}
    top={
      <div>
        <img
          style={{position: 'absolute', margin: 'auto', left: 0, right: 0, top: -20, width: 112}}
          src={SuperdaysSun}
        />
        <img style={{width: '100%'}} src={LowerCurve} />
      </div>
    }
  >
    <Col paddingVertical={5} spacing={3} align="center">
      <Col>
        <typo.H2 css={{textAlign: 'center'}}>Want some Superdays?</typo.H2>
        <typo.Body css={{textAlign: 'center'}}>Join now, it’s free while we’re in beta!</typo.Body>
      </Col>
      <RegisterForm message="The beta is just one step away!" />
    </Col>
  </StripWithContent>
)

export default RegisterStrip
