// @flow
import React from 'react'

import IntroStrip from '../blocks/IntroStrip'
import TopRegisterStrip from '../blocks/TopRegisterStrip'
import StepsStrip, {type StepType} from '../blocks/StepsStrip'
import DefaultLayout from '../components/DefaultLayout'
import FeaturesStrip from '../blocks/FeaturesStrip'
import BlogLinkStrip from '../blocks/BlogLinkStrip'
import TestimonialsStrip from '../blocks/TestimonialsStrip'
import RegisterStrip from '../blocks/RegisterStrip'
import {isUserKnown} from '../lib/is-known'
import SuperSevenStrip from '../blocks/SuperSevenStrip'
import {StripWithContent} from '../ui/layout'

const steps: StepType[] = [
  {
    title: 'Gift yourself some special time',
    text:
      'Do you want an adventure in your life, but it’s so hard to decide how exactly this is going to look like? Just block out whole days in your calendar and create space for Superdays.',
  },
  {
    title: 'Share the calendar with your friends',
    text:
      'Your friends fill your empty calendar with exciting events. If bungee-jumping off a bridge, horseback riding in a Zorro costume, a movie marathon with your friend’s favorites or gifting flowers to strangers sounds fun to you, then Superdays is made for you!',
  },
  {
    title: 'Adventure time',
    text:
      'Whether your friends join your events in real life or you’re flying solo keeping them updated online: All your friends can follow, comment and like, so your Superdays become the talk of the town and inspire others to create their own Superdays too. ',
  },
]

const RegisterPlaceholderStrip = () => <StripWithContent css={{height: '3rem'}} />

const AsTreatPage = () => (
  <DefaultLayout
    title="Let your friends take over your calendar!"
    description="Let your friends take over your calendar!"
  >
    <IntroStrip bottomWave="none" headline="Epic adventures, planned by friends!">
      Let your friends create special event-packed days <br />
      for you to enjoy for you to enjoy and share.
    </IntroStrip>
    {isUserKnown() ? (
      <RegisterPlaceholderStrip />
    ) : (
      <TopRegisterStrip formMessage="Sign me up – I want an adventure!" />
    )}
    <StepsStrip headline="Are you up for an adventurous challenge?" steps={steps} />
    <BlogLinkStrip headline="The story behind Superdays" />
    <FeaturesStrip />
    <SuperSevenStrip />
    <TestimonialsStrip />
    {isUserKnown() ? <div /> : <RegisterStrip />}
  </DefaultLayout>
)

export default AsTreatPage
