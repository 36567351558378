import * as React from 'react'

import colors from 'superdays-shared/colors'

import IconCheckmark from '../images/icon/icon__check.svg'

type CheckboxButtonProps = {
  isChecked: boolean,
  children: React.Node,
  onClick: boolean => void,
  style?: Object,
}

const CheckboxButton = ({children, isChecked, onClick, style}: CheckboxButtonProps) => {
  const containerStyle = {
    alignItems: 'center',
    color: colors.text,
    display: 'flex',
    cursor: 'pointer',
    userSelect: 'none',
    ...style,
  }
  const imageContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isChecked ? colors.white : 'transparent',
    width: 12,
    height: 12,
    borderRadius: 4,
    paddingLeft: 1,
    border: isChecked ? `1px solid ${colors.mintGreen}` : `1px solid ${colors.text}`,
  }
  const labelContainerStyle = {
    marginLeft: 10,
  }
  return (
    <div style={containerStyle} onClick={() => onClick(!isChecked)}>
      <div style={imageContainerStyle}>
        {isChecked ? <img alt="checkmark icon" src={IconCheckmark} /> : null}
      </div>
      <div style={labelContainerStyle}>{children}</div>
    </div>
  )
}

export default CheckboxButton
