// @flow
// We need this component as `react-slick` struggles with server side rendering (SSR).
// https://github.com/akiran/react-slick/issues/1245#issuecomment-463250189
import * as React from 'react'
import SlickSlider from 'react-slick'

type SSRSliderProps = {
  children: React.Node,
  responsive?: Object[],
  // $FlowFixMe
  innerRef?: React.Ref,
}
type SSRSliderState = {
  isClient: boolean,
}
class SSRSlider extends React.PureComponent<SSRSliderProps, SSRSliderState> {
  state = {
    isClient: false,
  }

  componentDidMount() {
    this.setState({
      isClient: true,
    })
  }

  render() {
    const {children, responsive, innerRef, ...rest} = this.props
    const {isClient} = this.state

    return (
      <SlickSlider
        ref={innerRef}
        key={isClient ? 'client' : 'server'}
        responsive={isClient ? responsive : null}
        {...rest}
      >
        {children}
      </SlickSlider>
    )
  }
}

// $FlowFixMe
export default React.forwardRef((props, ref) => <SSRSlider innerRef={ref} {...props} />)
