// @flow
import * as React from 'react'

import {StripWithContent, Col} from '../ui/layout'
import typo, {fontStyles, ExternalOrInternalLink} from '../ui/typo'

import SuperSevenGraphic from '../images/super-seven-challenge.svg'

const SuperSevenStrip = () => (
  <StripWithContent>
    <Col spacing={1} paddingVertical={6} align="center">
      <typo.H1 css={{textAlign: 'center'}}>Beat the Super7</typo.H1>
      <typo.BodyLarge>The most adventurous challenge of your lifetime!</typo.BodyLarge>
      <Col padding={2}>
        <img src={SuperSevenGraphic} alt="super seven challenge" />
      </Col>
      <Col align="center" spacing={0.5}>
        <typo.BodyBold css={{textAlign: 'center'}}>
          Would you rather gift Superdays to a friend? You can! 🎉
        </typo.BodyBold>
        <typo.Body css={{textAlign: 'center'}}>
          Get the app, invite your friends and plan truly unique days to gift.
        </typo.Body>
        <ExternalOrInternalLink href="/" css={[fontStyles.bodyBold, fontStyles.link()]}>
          Read More
        </ExternalOrInternalLink>
      </Col>
    </Col>
  </StripWithContent>
)

export default SuperSevenStrip
