// @flow
import React from 'react'
import RegisterForm from '../components/RegisterForm'
import {StripWithContent, Col} from '../ui/layout'
import {ScrollTarget} from '../components/ScrollManager'
import FormBackgroundImage from '../images/form__background.svg'
import {breakPoints} from '../ui/breakpoints'

const backgroundImageStyle = {
  marginTop: '2rem',
  backgroundImage: `url(${FormBackgroundImage})`,
  backgroundSize: 500,
  [breakPoints.mini]: {
    backgroundSize: '120%',
  },
}

type TRSProps = {
  formMessage: string,
}
class TopRegisterStrip extends React.Component<TRSProps> {
  inputNode = null

  render() {
    const {formMessage} = this.props
    return (
      <ScrollTarget
        scrollKey="top-register-strip"
        offset={50}
        onScroll={() => this.inputNode && this.inputNode.select()}
      >
        {ref => (
          <StripWithContent css={backgroundImageStyle}>
            <Col align="center" paddingVertical={4} innerRef={ref}>
              <RegisterForm message={formMessage} inputRef={node => (this.inputNode = node)} />
            </Col>
          </StripWithContent>
        )}
      </ScrollTarget>
    )
  }
}

export default TopRegisterStrip
