// @flow
import * as React from 'react'
import {navigate} from 'gatsby'
import * as EmailValidator from 'email-validator'

import colors from 'superdays-shared/colors'

import {FullButton} from '../ui/buttons'
import Input from '../ui/Input'
import {Col} from '../ui/layout'
import typo, {ExternalOrInternalLink, fontStyles} from '../ui/typo'
import {identify} from '../lib/analytics'
import {setUserAsKnown} from '../lib/is-known'
import CheckboxButton from '../ui/Checkbox'
import {breakPoints} from '../ui/breakpoints'

const addEmailtoDB = (email: string, tag?: string, occasion?: string) => {
  identify(email, {tag, occasion}, true)
  if (!process.env.GATSBY_FIREBASE_FUNCTIONS_URL) {
    throw new Error('GATSBY_FIREBASE_FUNCTIONS_URL missing')
  }
  return fetch(`${process.env.GATSBY_FIREBASE_FUNCTIONS_URL}/addToLeadQueue`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({email, tag, occasion}),
  })
}

type RFProps = {
  inputRef?: React.ElementRef<any>,
  occasion?: string,
  message: string,
}
type RFState = {
  email: string,
  isEmailValid: boolean,
  isLoading: boolean,
  isPrivacyAgreed: boolean,
  errorMessage: string,
}
export default class RegisterForm extends React.Component<RFProps, RFState> {
  state = {
    email: '',
    isPrivacyAgreed: false,
    isEmailValid: false,
    isLoading: false,
    errorMessage: '',
  }

  handleEmailChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value,
      isEmailValid: EmailValidator.validate(event.target.value),
      errorMessage: '',
    })
  }

  handlePrivacyChange = () => {
    this.setState(({isPrivacyAgreed}) => ({
      isPrivacyAgreed: !isPrivacyAgreed,
      errorMessage: '',
    }))
  }

  handleSubmit = (e: SyntheticEvent<HTMLButtonElement>) => {
    const {occasion} = this.props
    const {email, isEmailValid, isPrivacyAgreed} = this.state
    e.preventDefault()
    if (!isEmailValid) {
      this.setState({errorMessage: 'Please enter a valid email address'})
      return
    }
    if (!isPrivacyAgreed) {
      this.setState({errorMessage: 'You need to agree to the Privacy Policy :)'})
      return
    }
    this.setState({isLoading: true})
    return addEmailtoDB(email, 'landing-page', occasion).then(
      () => {
        setUserAsKnown()
        this.setState({email: '', isLoading: false, errorMessage: ''})
        navigate('/success', {state: {email}})
      },
      (e: Error) => {
        this.setState({isLoading: false})
        return Promise.reject(e)
      },
    )
  }

  render() {
    const {email, isLoading, isPrivacyAgreed, errorMessage} = this.state
    const {inputRef, message} = this.props
    return (
      <form css={{maxWidth: '20rem', width: '100%'}} onSubmit={this.handleSubmit} noValidate>
        <Col spacing={0.5} align="center">
          <typo.SmallBold css={{color: errorMessage ? colors.errorBackground : colors.text}}>
            {errorMessage || message}
          </typo.SmallBold>
          <Input
            inputRef={inputRef}
            css={{
              [breakPoints.notMini]: {
                minWidth: 330,
              },
              borderColor: errorMessage ? colors.errorBackground : colors.borderColor,
            }}
            type="email"
            email={email}
            onChange={this.handleEmailChange}
            placeholder="Your email"
            buttonWithin={
              <FullButton type="submit" disabled={isLoading}>
                Go
              </FullButton>
            }
          />
          <CheckboxButton isChecked={isPrivacyAgreed} onClick={this.handlePrivacyChange}>
            <typo.Small>
              I agree to the{' '}
              <ExternalOrInternalLink
                css={fontStyles.smallBoldBody}
                href="https://www.iubenda.com/privacy-policy/80217704/full-legal"
                rel="noopener noreferrer"
                target="_blank"
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                Privacy Policy
              </ExternalOrInternalLink>
            </typo.Small>
          </CheckboxButton>
        </Col>
      </form>
    )
  }
}
