// @flow
import * as React from 'react'
import Slider from './SSRSlider'

import colors from 'superdays-shared/colors'

import {Row} from '../ui/layout'

type DotProps = {
  isDark?: boolean,
  onClick?: () => void,
}
export const Dot = ({isDark, onClick}: DotProps) => (
  <div
    onClick={onClick}
    style={{
      width: 12,
      height: 12,
      borderRadius: '50%',
      border: `1px solid ${isDark ? colors.mintGreen : colors.lightWhite}`,
    }}
  />
)

type DCProps = {
  dots: React.Node[],
}
export const DotContainer = ({dots}: DCProps) => (
  <div
    className="slick-dots"
    style={{
      bottom: 20,
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Row>{dots}</Row>
  </div>
)

const getSettings = ({slidesToShow, onSlideChange, isDark}) => ({
  infinite: true,
  slidesToShow: slidesToShow || 1,
  slidesToScroll: slidesToShow || 1,
  appendDots: dots => <DotContainer dots={dots} />,
  customPaging: () => <Dot isDark={isDark} />,
  beforeChange: (current: number, next: number) => {
    if (onSlideChange) onSlideChange(next)
  },
  responsive:
    slidesToShow === 3
      ? [
          {
            breakpoint: 1250,
            settings: {slidesToShow: 2, slidesToScroll: 2},
          },
          {
            breakpoint: 900,
            settings: {slidesToShow: 1, slidesToScroll: 1},
          },
          {
            breakpoint: 700,
            settings: {slidesToShow: 1, slidesToScroll: 1, arrows: false, dots: true},
          },
        ]
      : [],
})

type SSProps = {
  slidesToShow?: number,
  onSlideChange?: number => void,
  children: React.Node,
  isDark?: boolean,
}
const ResponsiveSlider = ({slidesToShow, onSlideChange, isDark, children}: SSProps) => {
  const settings = getSettings({slidesToShow, onSlideChange, isDark})
  return <Slider {...settings}>{children}</Slider>
}

export default ResponsiveSlider
